import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/Router";
import PrimeVue from "primevue/config";

import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./index.css";
import "@/assets/theme.css";

import AutoComplete from "primevue/autocomplete";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import DeferredContent from "primevue/deferredcontent";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import DynamicDialog from "primevue/dynamicdialog";
import Fieldset from "primevue/fieldset";
import FocusTrap from "primevue/focustrap";
import Galleria from "primevue/galleria";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import Listbox from "primevue/listbox";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import Row from "primevue/row";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Skeleton from "primevue/skeleton";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tag from "primevue/tag";
import Terminal from "primevue/terminal";
import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
import Tree from "primevue/tree";
import TreeTable from "primevue/treetable";
import VirtualScroller from "primevue/virtualscroller";

createApp(App)

  .use(PrimeVue, {
    ripple: true,
    locale: {
      strong: "Fuerte",
      weak: "Débil",
      medium: "No muy segura",
      aria: {
        "aria-navigation": "Navegacion",
        slideNumber: "Numero",
        pageLabel: "Leyenda de pagina",
      },
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    },
  })
  .use(router)
  .use(ConfirmationService)
  .use(ToastService)
  .use(DialogService)

  .directive("tooltip", Tooltip)
  .directive("badge", BadgeDirective)
  .directive("ripple", Ripple)
  .directive("styleclass", StyleClass)
  .directive("focustrap", FocusTrap)

  .component("AutoComplete", AutoComplete)
  .component("Avatar", Avatar)
  .component("Badge", Badge)
  .component("BlockUI", BlockUI)
  .component("Breadcrumb", Breadcrumb)
  .component("Button", Button)
  .component("Calendar", Calendar)
  .component("Card", Card)
  .component("Carousel", Carousel)
  .component("Checkbox", Checkbox)
  .component("Chip", Chip)
  .component("Chips", Chips)
  .component("ColorPicker", ColorPicker)
  .component("Column", Column)
  .component("ConfirmDialog", ConfirmDialog)
  .component("ConfirmPopup", ConfirmPopup)
  .component("DataTable", DataTable)
  .component("DataView", DataView)
  .component("DataViewLayoutOptions", DataViewLayoutOptions)
  .component("DeferredContent", DeferredContent)
  .component("Dialog", Dialog)
  .component("Divider", Divider)
  .component("Dropdown", Dropdown)
  .component("DynamicDialog", DynamicDialog)
  .component("Fieldset", Fieldset)
  .component("Galleria", Galleria)
  .component("Image", Image)
  .component("InlineMessage", InlineMessage)
  .component("Inplace", Inplace)
  .component("InputMask", InputMask)
  .component("InputNumber", InputNumber)
  .component("InputText", InputText)
  .component("Listbox", Listbox)
  .component("Menu", Menu)
  .component("Menubar", Menubar)
  .component("Message", Message)
  .component("MultiSelect", MultiSelect)
  .component("OrderList", OrderList)
  .component("Paginator", Paginator)
  .component("Panel", Panel)
  .component("PanelMenu", PanelMenu)
  .component("Password", Password)
  .component("ProgressBar", ProgressBar)
  .component("ProgressSpinner", ProgressSpinner)
  .component("RadioButton", RadioButton)
  .component("Rating", Rating)
  .component("Row", Row)
  .component("SelectButton", SelectButton)
  .component("ScrollPanel", ScrollPanel)
  .component("ScrollTop", ScrollTop)
  .component("Slider", Slider)
  .component("Sidebar", Sidebar)
  .component("Skeleton", Skeleton)
  .component("SpeedDial", SpeedDial)
  .component("SplitButton", SplitButton)
  .component("TabMenu", TabMenu)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("Tag", Tag)
  .component("Textarea", Textarea)
  .component("Terminal", Terminal)
  .component("TieredMenu", TieredMenu)
  .component("Toast", Toast)
  .component("Toolbar", Toolbar)
  .component("ToggleButton", ToggleButton)
  .component("Tree", Tree)
  .component("TreeTable", TreeTable)
  .component("VirtualScroller", VirtualScroller)

  .mount("#app");
