import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home-page",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/directorio",
    name: "directorio-page",
    component: () => import("../views/About/Directorio.vue"),
  },
  {
    path: "/convocatoria",
    name: "convocatoria-page",
    component: () => import("../views/Convocatorias/ConvocatoriaDetail.vue"),
    props: (route) => {
      const { type_call } = route.query;
      return {
        type_call,
      };
    },
  },
  {
    path: "/registro_usuarios",
    name: "new_users-page",
    component: () => import("../views/Registros/NewUsers.vue"),
    props: (route) => {
      const { rolUsuario } = route.query;
      return {
        rolUsuario,
      };
    },
  },
  {
    path: "/aspirantes_docentes",
    name: "aspirante_docente-page",
    component: () => import("../views/Registros/NewDocentes.vue"),
  },
  {
    path: "/aspirantes_sedes",
    name: "aspirante_sede-page",
    component: () => import("../views/Registros/NewSedes.vue"),
  },
  {
    path: "/aspirantes_estudiantes",
    name: "aspirante_estudiante-page",
    component: () => import("../views/Registros/NewEstudiantes.vue"),
  },
  {
    path: "/login",
    name: "login-page",
    component: () => import("../views/auth/Singin.vue"),
    props: (route) => {
      const { rolUsuario } = route.query;
      return {
        rolUsuario,
      };
    },
  },
  {
    path: "/sedes",
    name: "Sedes",
    component: () => import("@/views/SedesCarreras/SedesDGP.vue"),
  },
  {
    path: "/sedesDGP",
    name: "SedesDGP",
    component: () => import("@/views/SedesCarreras/SedesDGPList.vue"),
  },
  {
    path: "/carreras_y_mallas",
    name: "Carreras_y_Mallas",
    component: () => import("@/views/SedesCarreras/CarrerasMallas.vue"),
  },
  {
    path: "/plan_de_estudio",
    name: "plan_de_estudio",
    component: () => import("@/views/SedesCarreras/PlanEstudio.vue"),
  },
  {
    path: "/area_de_conocimiento",
    name: "area_de_conocimiento",
    component: () => import("@/views/SedesCarreras/AreaConocimiento.vue"),
  },
  {
    path: "/|:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(function () {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
